@keyframes moveBackground {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.body1 {
    background: linear-gradient(220deg, #636b8445, #0604043d, #0d106e3d);
    background-size: 400% 400%;
    animation: moveBackground 2s ease infinite;
    color: white;
}

.password-toggle {
    cursor: pointer;
    user-select: none;
}

.registrbox {
    background-color: rgba(22, 51, 97, 0.586);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(10, 30, 7, 0.165);

    max-height: 900px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}